.Appsview .desc {
  font-size: 1.3rem;
  padding: 0 2rem 1rem;
}
@media (prefers-color-scheme: dark) {
  .Appsview .desc {
    color: var(--color-gs80);
  }
}
@media (prefers-color-scheme: light) {
  .Appsview .desc {
    color: #14141a;
  }
}