.error-panel .status-img-wrapper {
  padding-top: 17%;
}
.error-panel .error-msg-wrapper .content {
  margin: auto;
  height: 7.8rem;
}
.error-panel .btn-wrapper {
  display: flex;
  justify-content: center;
  padding: 1.7rem 0px;
}