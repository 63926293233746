.update-indicator {
  width: 2.8rem;
  height: 2.2rem;
  background: #d90036;
  border-radius: 1.1rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.update-indicator > span {
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--color-gs00);
}

.app-item .icon-wrapper {
  width: 5.6rem;
  height: 5.6rem;
  min-width: 16%;
}

.unverified-warning .container .icon {
  content: " ";
  background-image: url(/20220926071425576/dist/c7f0812bfe9c4a9668c43848dce7675c.svg);
  display: inline-block;
  background-size: cover;
}
@media (prefers-color-scheme: dark) {
  .unverified-warning .container .icon {
    filter: invert(53%) sepia(9%) saturate(532%) hue-rotate(201deg) brightness(94%) contrast(87%);
  }
}
@media (prefers-color-scheme: light) {
  .unverified-warning .container .icon {
    filter: invert(54%) sepia(10%) saturate(436%) hue-rotate(201deg) brightness(93%) contrast(89%);
  }
}

.Page {
  --pillbutton-height: 5rem;
  width: 100%;
  z-index: 1000;
  box-sizing: border-box;
  font-size: 1.8rem;
  display: flex;
  flex-flow: column;
  height: 100%;
}

@media (prefers-color-scheme: light) {
  .subtitle::after,
.subtitle::before {
    background-color: var(--color-gs100);
  }
}
.top-section {
  padding: 2rem 1.5rem 2rem 2rem;
}

.detail-header-wrapper .info-row {
  display: flex;
  margin-bottom: 0.5rem;
}
.detail-header-wrapper .action-row {
  display: flex;
  margin-bottom: 1.8rem;
}
.detail-header-wrapper .left {
  width: 8.4rem;
}
.detail-header-wrapper .right {
  margin-left: 1.5rem;
}
.detail-header-wrapper .info-row .title {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 22.6rem;
  overflow: hidden;
}
.detail-header-wrapper .info-row .subtitle {
  width: 22.6rem;
  color: var(--color-gs80);
  max-height: 4.2rem;
  overflow: hidden;
  position: relative;
  padding-right: 1rem;
}
.detail-header-wrapper .info-row .subtitle::before {
  position: absolute;
  content: "...";
  bottom: 0;
  right: 1rem;
  background: var(--color-gs00);
}
.detail-header-wrapper .info-row .subtitle::after {
  content: "";
  position: absolute;
  right: 1rem;
  width: 2rem;
  height: 2rem;
  background: var(--color-gs00);
}
.detail-header-wrapper .app-icon-component {
  width: 8.4rem;
  height: 8.4rem;
  background-size: contain;
}
.detail-header-wrapper .paid-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.detail-header-wrapper .paid-wrapper .status {
  margin-top: 0.6rem;
  font-size: 1.4rem;
}
.detail-header-wrapper .paid-wrapper .status-icon {
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 50%;
  position: relative;
}
.detail-header-wrapper .paid-wrapper .status-icon > i {
  position: absolute;
  font-size: 2.5rem;
  top: -0.5rem;
  color: #000000;
}
.detail-header-wrapper .paid-wrapper .status-icon[data-enable=true] {
  background: #00bf60;
}
.detail-header-wrapper .paid-wrapper .status-icon[data-enable=false] {
  background: #d90036;
}
.detail-header-wrapper .paid-wrapper .status-icon[data-enable=false]::before {
  content: " ";
  width: 0.3rem;
  height: 1.55rem;
  background: #000000;
  position: absolute;
  transform: rotate(45deg);
  left: 1.1rem;
  top: 0.4rem;
  border-radius: 25%;
}
.detail-header-wrapper .paid-wrapper .status-icon[data-enable=false]::after {
  content: " ";
  width: 0.3rem;
  height: 1.55rem;
  background: #000;
  position: absolute;
  transform: rotate(-45deg);
  left: 1.1rem;
  top: 0.4rem;
  border-radius: 25%;
}
.detail-header-wrapper .paid-wrapper > .kaipay-logo-wrapper {
  width: 6.8rem;
  height: 2.2rem;
  border-radius: 1.1rem;
  background: var(--color-gs20);
  margin-top: 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.detail-header-wrapper .paid-wrapper > .kaipay-logo-wrapper > .logo {
  background-image: url(/20220926071425576/dist/f1ecba4103ba1720ca4e4b43158acb2b.svg);
  background-size: contain;
  width: 4.7rem;
  height: 1.4rem;
}

@media (prefers-color-scheme: light) {
  .action-row kai-pillbutton[action=open] > div > button {
    background-color: var(--color-gs00);
    color: var(--color-gs100);
  }
  .action-row kai-pillbutton[action=open] > div > button:active {
    background-color: var(--color-gs20);
  }
}
.action-row kai-pillbutton {
  --pillbutton-width: 22rem;
  --primary-font-color: var(--color-gs00);
}
.action-row i.kai-pillbutton {
  font-size: 0;
}
.action-row kai-pillbutton[action=buy],
.action-row kai-pillbutton[action=stop] {
  --button-background-color: var(--color-purple);
}
.action-row kai-pillbutton[action=get] {
  --button-background-color: var(--color-blue);
}
.action-row kai-pillbutton[action=get]:active {
  --theme-color-d: var(--color-blue-dark);
}
.action-row kai-pillbutton[action=update] {
  --button-background-color: var(--color-cyan);
}
.action-row kai-pillbutton[action=update]:active {
  --theme-color-d: var(--color-cyan-dark);
}
.action-row kai-pillbutton[action=open] {
  --button-background-color: var(--color-gs100);
}
.action-row kai-pillbutton[action=open]:active {
  --theme-color-d: var(--color-gs60);
}
.action-row kai-pillbutton[action=pin] {
  --button-background-color: var(--color-orange);
}
.action-row kai-pillbutton[action=pin]:active {
  --theme-color-d: var(--color-orange-dark);
}

.divider {
  margin: 1.8rem 0.5rem 0;
  width: 100%;
  height: 0.2rem;
  border-radius: 0.1rem;
  background-color: var(--color-gs40);
}

.desc-wrapper {
  padding: 0 2rem;
  box-sizing: border-box;
}

.restart-msg {
  font-size: 1.6rem;
  font-weight: bold;
  color: var(--color-gs100);
}

.screenshot-wrapper {
  margin: 2.5rem 0.5rem 2.5rem 0;
}
.screenshot-wrapper > .btn {
  width: 32rem;
  height: 6rem;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 3rem;
  justify-content: center;
  align-items: center;
  display: flex;
  box-sizing: border-box;
  background-color: var(--color-gs00);
  color: var(--color-gs100);
  border: 0.2rem solid var(--color-gs80);
}
.screenshot-wrapper > .btn:active {
  background-color: var(--color-gs20);
}
.screenshot-wrapper > .btn > .icon-wrapper {
  width: 2.4rem;
  line-height: 5.6rem;
}
.screenshot-wrapper > .btn > .icon-wrapper > i {
  font-size: 2.4rem;
}
.screenshot-wrapper > .btn > .title {
  padding-right: 1.2rem;
}

.leftover-detail-wrapper .item {
  padding: 1.3rem 0;
}
.leftover-detail-wrapper .item .title {
  color: var(--color-gs60);
  text-transform: capitalize;
}
.leftover-detail-wrapper .item .content {
  color: var(--color-gs100);
  font-weight: 600;
}

.website-info {
  font-size: 1.4rem;
}
.website-info .title {
  color: var(--color-orange);
}
.website-info .website-url {
  color: var(--color-gs80);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 22.6rem;
}

.progress-wrapper {
  margin-top: -0.6rem;
}

.readmore-wrapper {
  height: 7.2rem;
  min-height: 7.2rem;
  overflow: hidden;
  max-height: 100%;
  flex: 1 1 auto;
  position: relative;
}
.readmore-wrapper.expanded {
  height: unset;
  flex-shrink: 0;
  max-height: unset;
}
.readmore-wrapper kai-pillbutton {
  position: absolute;
  bottom: 0;
  --pillbutton-width: 100%;
}

.read-more-overlay {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 4.8rem;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), var(--color-gs00));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}
.read-more-overlay .button {
  padding: 0.8rem 2rem;
  border-radius: 30px;
  border: solid 2px var(--color-gs10);
  background-color: var(--color-gs00);
  font-size: 1.6rem;
  font-weight: bold;
}

.screenshots-section {
  height: 20rem;
  box-sizing: border-box;
  flex-direction: row;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 2rem 0rem;
  flex-shrink: 0;
}
.screenshots-section .screenshot {
  width: 9rem;
  height: 16rem;
  margin: 0 0.5rem;
  border-radius: 10px;
  border: solid 1px var(--color-gs80);
  overflow: hidden;
  box-sizing: border-box;
  flex-shrink: 0;
}
.screenshots-section .screenshot:first-child {
  margin-left: 2rem;
}
.screenshots-section .screenshot > img {
  width: 100%;
  height: 100%;
}

.unverified-warning {
  padding-bottom: 2rem;
  font-size: 1.3rem;
}
@media (prefers-color-scheme: dark) {
  .unverified-warning {
    color: var(--color-gs60);
  }
}
@media (prefers-color-scheme: light) {
  .unverified-warning {
    color: var(--color-gs40);
  }
}
.unverified-warning .container {
  padding: 0 2rem;
  display: flex;
}
.unverified-warning .container .icon {
  width: 2rem;
  height: 2rem;
  margin-right: 0.5rem;
  margin-top: 0.8rem;
}
.unverified-warning .container .text {
  max-width: 29.5rem;
  display: inline-block;
}