.dev-panel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
}
.dev-panel .developer-name {
  text-align: center;
  height: 3.2rem;
  font-size: 2.2rem;
  font-weight: bold;
  margin-bottom: 1.2rem;
  color: var(--color-gs100);
}
.dev-panel .developer-section {
  box-sizing: border-box;
  width: 100%;
  border-radius: 2.25rem;
  border: solid 2px var(--color-gs80);
  padding: 1rem 2rem;
  max-height: calc(100% - 13rem);
  overflow: hidden;
  margin-bottom: 2.2rem;
}
.dev-panel .developer-section .scrollbar-wrapper {
  padding-right: 1.8rem;
  overflow: auto;
  width: 100%;
  height: 100%;
}
.dev-panel .developer-section .scrollbar-wrapper .links-section .kai-1line-listitem {
  padding: 0;
  margin: 0;
}
.dev-panel .developer-section .scrollbar-wrapper .links-section kai-1line-listitem {
  width: 28rem;
}
.dev-panel .developer-section .scrollbar-wrapper .links-section .multiple-supports-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.dev-panel .developer-section .scrollbar-wrapper .links-section .multiple-supports-wrapper button.support-button {
  outline: none;
  transition: all 0.3s;
  height: 3.6rem;
  border-radius: 1.8rem;
  background: none;
  background-color: var(--color-gs10);
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  border: 0;
}
.dev-panel .developer-section .scrollbar-wrapper .links-section .multiple-supports-wrapper button.support-button:active {
  background-color: var(--color-purple);
}
.dev-panel .developer-section .scrollbar-wrapper .links-section .multiple-supports-wrapper button.support-button div {
  padding: 0 1rem;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.dev-panel .developer-section .scrollbar-wrapper .links-section .multiple-supports-wrapper button.support-button div i {
  color: var(--color-gs100);
  font-size: 2rem;
  margin-right: 0.6rem;
}
.dev-panel .developer-section .scrollbar-wrapper .links-section .multiple-supports-wrapper button.support-button div span {
  font-size: 1.6rem;
  font-weight: bold;
  color: var(--color-gs100);
  text-transform: capitalize;
}
.dev-panel .developer-section .scrollbar-wrapper .divider {
  width: 28rem;
  height: 0.2rem;
  border-radius: 0.1rem;
  margin: 1rem 0;
  background-color: var(--color-gs100);
}
.dev-panel .developer-section .scrollbar-wrapper .apps-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.dev-panel .developer-section .scrollbar-wrapper .apps-section .developer-app-title {
  height: 2.2rem;
  font-size: 1.4rem;
  font-weight: bold;
  color: var(--color-gs40);
  margin-bottom: 1.5rem;
}
.dev-panel .developer-section .scrollbar-wrapper .apps-section .apps {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
}
.dev-panel .developer-section .scrollbar-wrapper .apps-section .apps .developer-app {
  width: 8.4rem;
  height: 7.7rem;
  margin-bottom: 1.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dev-panel .developer-section .scrollbar-wrapper .apps-section .apps .developer-app:nth-child(3n+2) {
  margin: 0 1.2rem;
}
.dev-panel .developer-section .scrollbar-wrapper .apps-section .apps .developer-app .icon {
  height: 5.6rem;
  width: 5.6rem;
}
.dev-panel .developer-section .scrollbar-wrapper .apps-section .apps .developer-app .app-name {
  font-size: 1.4rem;
  line-height: 1.9rem;
  width: 8.4rem;
  height: 1.9rem;
  font-weight: 600;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.hidden {
  display: none;
}