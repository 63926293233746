.more-panel i[slot=listitem-right-slot] {
  color: var(--color-gs20);
}
.more-panel .list-item {
  position: relative;
}
.more-panel .update-indicator {
  position: absolute;
  right: 5.5rem;
  top: 1.9rem;
}