.screenshot-panel-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.screenshot-panel-wrapper .header {
  padding: 2.5rem 0 2.3rem;
  display: flex;
  align-items: end;
  flex-direction: column;
}
.screenshot-panel-wrapper .header .icon-wrapper {
  width: 3.2rem;
  height: 3.2rem;
  position: relative;
  margin-inline-start: 1.5rem;
  direction: rtl;
}
.screenshot-panel-wrapper .header .icon-wrapper .icon:before,
.screenshot-panel-wrapper .header .icon-wrapper .icon:after {
  position: absolute;
  left: 1.5rem;
  top: 0.5rem;
  content: " ";
  height: 2.12rem;
  width: 0.3rem;
  background-color: var(--color-gs100);
  border-radius: 0.5rem;
}
.screenshot-panel-wrapper .header .icon-wrapper .icon:before {
  transform: rotate(45deg);
}
.screenshot-panel-wrapper .header .icon-wrapper .icon:after {
  transform: rotate(-45deg);
}
.screenshot-panel-wrapper .viewer-wrapper {
  position: absolute;
  white-space: nowrap;
  width: 100%;
}
.screenshot-panel-wrapper .viewer-wrapper .img-wrapper {
  max-width: 24rem;
  height: 42.8rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 1rem;
  vertical-align: middle;
  opacity: 0.5;
  overflow: clip;
}
.screenshot-panel-wrapper .viewer-wrapper .img-wrapper > img {
  object-fit: contain;
  width: 100%;
}
.screenshot-panel-wrapper .viewer-wrapper .img-wrapper .placeholder {
  height: 42.8rem;
  width: 24rem;
  background-color: var(--color-gs20);
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.screenshot-panel-wrapper .viewer-wrapper .img-wrapper .placeholder > i {
  color: var(--color-gs10);
  font-size: 10rem;
}
.screenshot-panel-wrapper .viewer-wrapper .img-wrapper .placeholder.hidden {
  display: none;
}
.screenshot-panel-wrapper .viewer-wrapper .current {
  opacity: 1;
}