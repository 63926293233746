.Loading {
  height: 100%;
}
.Loading .content-wrapper {
  width: 100%;
  height: 36rem;
  padding-top: 7.5rem;
}
.Loading .content-wrapper .logo-wrapper {
  padding-top: 8.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Loading .content-wrapper .logo-wrapper .logo {
  background-image: url(/20220926071425576/dist/b75e34c274e08e18d12d62f3662fffd9.svg);
  width: 19.8rem;
  height: 13.7rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
}
.Loading .content-wrapper .loader-wrapper {
  margin-top: 11.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Loading .content-wrapper .loader-wrapper .loader {
  background-image: url(/20220926071425576/dist/3f5f4b3ff8df8a382aa54ce073cbc946.png);
  width: 5.6rem;
  height: 5.6rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
}

.Loading.with-bg {
  background-color: var(--color-purple);
  height: 100%;
}
.Loading.with-bg::after {
  content: " ";
  width: 64rem;
  height: 64rem;
  background: #ffffff;
  border-radius: 0 0 0 20rem;
  margin-top: -20rem;
  position: absolute;
  bottom: 7.5rem;
}