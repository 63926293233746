.default-background {
  background-image: url(/20220926071425576/dist/9f96a83d89c2fa62ebaaff7352fd2977.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.hide {
  display: none;
}

.app-icon-component {
  width: 100%;
  height: 100%;
}
.app-icon-component .icon-wrapper {
  width: 100%;
  height: 100%;
  font-size: 0;
}
.app-icon-component .icon-wrapper .app-icon {
  width: 100%;
  height: 100%;
}

.bookmark-globe {
  background-size: contain;
  background-image: url(/20220926071425576/dist/5249b5aa27c926a72a24a8e676fa60b6.png);
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  width: 5.6rem;
  height: 5.6rem;
}
.bookmark-globe .icon-wrapper {
  width: 48% !important;
  height: 48% !important;
  margin: auto;
}
.bookmark-globe .icon-wrapper .app-icon {
  width: 100%;
  height: 100%;
}

.pwa-icon {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pwa-icon > span {
  color: #fff;
  font-size: 2.4rem;
  font-weight: bold;
}

.Page .pwa-icon > span {
  font-size: 3.6rem;
}