.update-indicator {
  width: 2.8rem;
  height: 2.2rem;
  background: #d90036;
  border-radius: 1.1rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.update-indicator > span {
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--color-gs00);
}

.app-item .icon-wrapper {
  width: 5.6rem;
  height: 5.6rem;
  min-width: 16%;
}

.Appsview .app-item[data-type=openpwa] .title::after {
  content: " ";
  background-image: url(/20220926071425576/dist/c7f0812bfe9c4a9668c43848dce7675c.svg);
  display: inline-block;
  background-size: cover;
}
@media (prefers-color-scheme: dark) {
  .Appsview .app-item[data-type=openpwa] .title::after {
    filter: invert(53%) sepia(9%) saturate(532%) hue-rotate(201deg) brightness(94%) contrast(87%);
  }
}
@media (prefers-color-scheme: light) {
  .Appsview .app-item[data-type=openpwa] .title::after {
    filter: invert(54%) sepia(10%) saturate(436%) hue-rotate(201deg) brightness(93%) contrast(89%);
  }
}

.Appsview .app-item {
  font-size: large;
  text-align: center;
  color: var(--GS00);
  border: 1rem solid var(--GS00);
  width: 100%;
  background-color: var(--GS20);
}
.Appsview .app-item div[slot=listitem-right-slot].download-indicator {
  animation: spin 1s linear infinite;
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
.Appsview .app-item .subtitle-1,
.Appsview .app-item .subtitle-2 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.Appsview .app-item .title {
  font-size: calc(var(--baseline) * 1.8);
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}
.Appsview .app-item[data-type=openpwa] .title::after {
  width: 1.8rem;
  height: 1.8rem;
  margin-left: 0.4rem;
}
.Appsview .link-app {
  width: 100%;
  height: 7rem;
  background-position: center;
  background-size: cover;
}