.update-panel .header-wrapper {
  position: relative;
}
.update-panel .update-indicator {
  position: absolute;
  right: 1.8rem;
  top: 2.9rem;
}
.update-panel .section:first-child {
  margin-bottom: 3.2rem;
}
.update-panel .btn-wrapper {
  display: flex;
  justify-content: center;
  padding: 1.7rem 0px;
}