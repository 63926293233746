.update-indicator {
  width: 2.8rem;
  height: 2.2rem;
  background: #d90036;
  border-radius: 1.1rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.update-indicator > span {
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--color-gs00);
}

.app-item .icon-wrapper {
  width: 5.6rem;
  height: 5.6rem;
  min-width: 16%;
}