.search-panel .search-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.search-panel .search-list .message {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.search-panel .search-list .message p {
  font-size: 1.8rem;
  text-align: center;
  font-weight: bold;
}
.search-panel .search-list .message div {
  background-image: url(/20220926071425576/dist/3f5f4b3ff8df8a382aa54ce073cbc946.png);
  width: 5.6rem;
  height: 5.6rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
}
.search-panel .search-list .kai-1line-listitem {
  padding: 0;
  margin: 0;
}
.search-panel .search-list kai-1line-listitem {
  height: 7rem;
  padding: 0 1rem;
}
.search-panel .search-list kai-1line-listitem .app-icon-wrapper {
  width: 5.6rem;
  height: 5.6rem;
  margin: 0.7rem 1rem 0.7rem 0;
}
.search-panel .search-list kai-1line-listitem .middle-children {
  width: calc(100% - 7.6rem);
}
.search-panel .search-list kai-1line-listitem .middle-children .subtitle-1 {
  line-height: 7rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.search-panel .search-img-wrapper {
  height: 16rem;
  padding-top: 16.5rem;
  display: flex;
  justify-content: center;
}
.search-panel .search-img-wrapper .search-img {
  width: 16rem;
  height: 16rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
}
.search-panel .search-msg-wrapper {
  padding-top: 3rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.search-panel .search-msg-wrapper .content {
  height: 7.8rem;
  color: var(--color-gs100);
  font-size: 1.8rem;
  text-align: center;
  width: 30rem;
  margin: 1rem auto 0 auto;
  max-height: 9rem;
}

@media (prefers-color-scheme: dark) {
  .search-img[data-search=all], .search-img[data-search=apps], .search-img[data-search=discover] {
    background-image: url(/20220926071425576/dist/f8a05f3989f0cfcdee5fc93100fb041a.svg);
  }
  .search-img[data-search=games] {
    background-image: url(/20220926071425576/dist/f0a4b07210b829ccfd9f0c5231ca64e2.svg);
  }
}
@media (prefers-color-scheme: light) {
  .search-img[data-search=all], .search-img[data-search=apps], .search-img[data-search=discover] {
    background-image: url(/20220926071425576/dist/5587fe485ed9df169f36625b69fba424.svg);
  }
  .search-img[data-search=games] {
    background-image: url(/20220926071425576/dist/6253eb08205ed94516b98969a197e243.svg);
  }
}