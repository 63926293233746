.large-text .Loading h1,
.large-text .info h2 {
  font-size: 2.2rem;
}
.large-text .brick:focus .info-box .info.full p {
  font-size: 1.5rem;
  max-height: 4.5rem;
}
.large-text .brick .info-box .info {
  height: auto;
}
.large-text .website-url {
  font-size: 1.4rem;
}