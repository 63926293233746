:root {
  --root-font-size: 8.8px;
}

html,
body,
#root,
#app {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  outline: none;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  --theme-color: var(--color-purple);
  --color-cyan: #1cc1c4;
  --frog-green: #75cf0b;
  --azure: #00a6fb;
  --electric-purple: #c02bff;
}

body {
  background-color: var(--color-gs00);
  color: var(--color-gs100);
  outline: none;
  -moz-user-select: none;
}

body * {
  outline: none;
}

.content {
  width: 100%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.skbar {
  background-color: #000000;
}

.panel {
  flex: auto;
  overflow-x: hidden;
  overflow-y: auto;
}
.panel.hide {
  display: none;
}
.panel.hide[data-name=page] {
  display: block;
}
.panel > div {
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
}

.AppList h1 {
  width: 100%;
  text-align: center;
  height: 4rem;
  background-color: #696bff;
  font-size: 1.7rem;
  color: var(--color-gs00);
  margin: 0;
  padding: 4rem 0 0 0;
}

.dialog-container .dialog > .header {
  text-transform: capitalize;
}

.update-dialog {
  width: 100%;
  height: 17rem;
  background-color: #e9e9e9;
  position: fixed;
  top: calc(100% - 17rem);
  text-align: center;
  color: #000000;
  font-size: 1.2rem;
  opacity: 1;
  z-index: 1000;
}
.update-dialog.hide {
  top: 100%;
  opacity: 0;
}

gaia-tabs {
  background: transparent;
}
gaia-tabs > .selected {
  color: var(--color-gs00);
  font-weight: inherit;
}

#category-bar .update-available-indicator > div > div:last-child > * {
  background-color: rgba(217, 0, 54, 0.4);
  width: 100%;
  height: 100%;
  border-radius: 2.5rem;
  line-height: 4rem;
  box-sizing: border-box;
}

h1 {
  font-size: 4rem;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0;
}

h2 {
  font-size: 2.8rem;
  font-weight: bold;
  margin: 0;
}

h3 {
  font-size: 2.2rem;
  font-weight: bold;
  margin: 0;
}

h4 {
  font-size: 1.6rem;
  font-weight: bold;
  margin: 0;
}

h5 {
  font-size: 1.4rem;
  font-weight: bold;
  margin: 0;
}

h6 {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0;
}

.sub-1 {
  font-size: 1.8rem;
  font-weight: 600;
}

.sub-2 {
  font-size: 1.4rem;
}

small {
  font-size: 1.6rem;
}

#ssr {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}