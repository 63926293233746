.status-img-wrapper {
  height: 16rem;
  padding-top: 4.5rem;
  display: flex;
  justify-content: center;
}
.status-img-wrapper .status-img {
  width: 16rem;
  height: 16rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
}

.error-msg-wrapper {
  padding-top: 3rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.error-msg-wrapper > div {
  z-index: 1;
}
.error-msg-wrapper .title {
  color: var(--color-gs100);
  font-size: 2.2rem;
  font-weight: bold;
  text-align: center;
}
.error-msg-wrapper .content {
  color: var(--color-gs100);
  font-size: 1.8rem;
  text-align: center;
  width: 30rem;
  margin: 1rem auto 0 auto;
  max-height: 9rem;
}

@media (prefers-color-scheme: dark) {
  .status-img[data-status=offline] {
    background-image: url(/20220926071425576/dist/a93824c51807b2bada42705ab7c75e21.svg);
  }
  .status-img[data-status=noAvailableApp], .status-img[data-status=no-download-history] {
    background-image: url(/20220926071425576/dist/f8a05f3989f0cfcdee5fc93100fb041a.svg);
  }
  .status-img[data-status=account-not-sign-in] {
    background-image: url(/20220926071425576/dist/5669ef17fc9668ed0d961eb820e13951.svg);
  }
  .status-img[data-status=no-update] {
    background-image: url(/20220926071425576/dist/1be28a7a4fe25425586a2cb0bc63407e.svg);
  }
}
@media (prefers-color-scheme: light) {
  .status-img[data-status=offline] {
    background-image: url(/20220926071425576/dist/3ef232e71d6715fd0ef63e53873f9e60.svg);
  }
  .status-img[data-status=noAvailableApp], .status-img[data-status=no-download-history] {
    background-image: url(/20220926071425576/dist/5587fe485ed9df169f36625b69fba424.svg);
  }
  .status-img[data-status=account-not-sign-in] {
    background-image: url(/20220926071425576/dist/d0cfde0c645b19e87fad1a6b341ffb54.svg);
  }
  .status-img[data-status=no-update] {
    background-image: url(/20220926071425576/dist/5bc8985aabab7da9acb7dc054c65e096.svg);
  }
}